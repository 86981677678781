import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { SettingsStore, SettingsShape } from "src/stores/Settings";
import { PageStore } from "src/stores/Page";
import Layout from "src/layouts/Main";
import HeaderSpace from "src/organisms/Header/Spacer";
import NotFound from "src/organisms/NotFound";
import Helmet from "react-helmet";
import favicon from "src/images/favicon.ico";
import Spacer from "src/organisms/PageComponentList/Spacer";
import trackEvent from "./trackEvent";

const NotFoundPage = ({ data, pageContext: { locale, localizedSlugs } }) => {
  if (!data) {
    throw new Error(`Page query failed: reason unknown`);
  }
  const settings = {
    ...data.contentfulSettings,
    locale,
    localizedSlugs,
  };

  const notFound = data.contentfulNotFound;

  useEffect(() => {
    window.tc_vars = {
      country: locale.currentRegion.region,
      environment: process.env.GATSBY_TRACKING_ENV,
      page_category: "404",
      page_name: notFound.title,
      page_type: "Not Found Page",
      page_subcategory: "",
      work_env: process.env.GATSBY_TRACKING_ENV,
      brand: "Activia",
    };
    window.tC?.container?.reload({ events: { page_view: [{}, {}] } });

    trackEvent();
    //console.log('window.tc_vars not found',window.tc_vars);
  }, []);

  return (
    <SettingsStore value={settings}>
      <PageStore>
        <Layout forceVisibleHeader locale={locale}>
          <Helmet
            link={[
              { rel: "shortcut icon", type: "image/ico", href: `${favicon}` },
            ]}
            title={notFound.title}
          />
          <HeaderSpace>
            <NotFound {...notFound} />
            <Spacer />
          </HeaderSpace>
        </Layout>
      </PageStore>
    </SettingsStore>
  );
};

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    contentfulSettings: PropTypes.shape(SettingsShape).isRequired,
    contentfulNotFound: PropTypes.object.isRequired,
  }),
  pageContext: PropTypes.shape({
    locale: PropTypes.object.isRequired,
    localizedSlugs: PropTypes.instanceOf(Array),
  }).isRequired,
};

export const pageQuery = graphql`
  query getNotFoundPage($nodeLocale: String!, $id: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...SettingsFields
    }
    contentfulNotFound(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      title
      subtitle
      firstSuggestion {
        ...Cta
      }
      secondSuggestion {
        ...Cta
      }
    }
  }
`;

export default NotFoundPage;
